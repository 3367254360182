<template>
  <div class="subpage" style="margin-top: 130px">
    <div class="post p-content">
      <div class="title-area">
        <div class="title">
          <div class="post-title">
            <h1>
              {{ post.title }}
            </h1>
            <p class="date">Written on {{ dateInWords(post.created_at) }}</p>
            <div class="social">
              <a
                :href="post.twitter"
                onclick="window.open(this.href, 'mywin',
'left=20,top=20,width=500,height=500,toolbar=1,resizable=0'); return false;"
                class="button facebook"
                ><i class="fa fa-facebook"></i> - Share it!</a
              >

              <a
                :href="post.facebook"
                onclick="window.open(this.href, 'mywin',
'left=20,top=20,width=500,height=500,toolbar=1,resizable=0'); return false;"
                class="button twitter"
                ><i class="fa fa-twitter"></i> - Tweet it!</a
              >
            </div>
            <br />
          </div>
        </div>
      </div>

      <div class="post-content" v-html="post.content"></div>
      <br />
      <p class="tag"><strong>Tags:</strong> {{ post.tags }}</p>
    </div>
  </div>
</template>
<script>
import posts from "@/assets/posts.json";
export default {
  data() {
    return {
      posts
    };
  },
  computed: {
    post: function() {
      return this.posts.filter(post => post.slug === this.$route.params.id)[0];
    }
  },
  methods: {
    dateInWords(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      };
      const date = new Date(timestamp);
      return date.toLocaleString("default", options);
    }
  }
};
</script>
<style scoped>
.post-title > h1:nth-child(1) {
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
}
.post-content {
  font-size: 120%;
  line-height: 106%;
}
.post-content >>> p:nth-child(n) {
  padding-bottom: 18px;
}
.post-content >>> li:nth-child(n) {
  padding-bottom: 18px;
}
>>> img {
  /* padding-left: 35%; */
}
.p-content {
  text-align: justify;
  text-justify: inter-word;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.post-content.p {
  padding-top: 10px !important;
}
.post-content > p:nth-child {
  font-size: 20px;
}
</style>
