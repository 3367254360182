<template>
  <div>
    <ul class="dropdown-nav">
      <li><router-link to="/">Home</router-link></li>
      <li>
        <router-link to="/is-he-here-yet" class="is-he-here"
          >#IsHeHereYet</router-link
        >
      </li>
      <li>
        <router-link to="/are-you-here-yet" class="is-he-here"
          >#AreYouHereYet</router-link
        >
      </li>
      <li><router-link to="/about-tony">About Dr. Tony Ortega</router-link></li>

      <li>
        <router-link to="/psychotherapy-services"
          >Psychotherapy Services</router-link
        >
      </li>
      <li>
        <router-link to="/lgbtq-issues">LGBTQ+-Specific Issues</router-link>
      </li>
      <li>
        <router-link to="/evaulation-services">Evaluation Services</router-link>
      </li>
      <li>
        <router-link to="/insurance-and-fees">Insurance and Fees</router-link>
      </li>
      <li>
        <router-link to="/forms"
          >Forms</router-link
        >
      </li>
      <li>
        <router-link to="/recommended-readings"
          >Recommended Readings</router-link
        >
      </li>
      <li><router-link to="/media">Media</router-link></li>
      <li><router-link to="/contact-me">Contact Me</router-link></li>
      <li><router-link to="/blog">Blog</router-link></li>
    </ul>
    <a id="top"></a>
    <header>
      <router-link to="/" class="logo desktop"
        ><img src="/assets/img/logo.png" alt=""
      /></router-link>
      <router-link to="/" class="logo mobile"
        ><img src="/assets/img/mobile.png" alt=""
      /></router-link>
      <ul>
        <li>
          <a href="tel:3476899914"><i class="fa fa-phone"></i></a>
        </li>
        <li>
          <router-link to="/contact-me"
            ><i class="fa fa-envelope-o"></i
          ></router-link>
        </li>
        <li>
          <a class="fb" href="https://www.facebook.com/drtonyortega/"
            ><i class="fa fa-facebook"></i
          ></a>
        </li>
        <li>
          <a class="tw" href="https://twitter.com/DrTonyOrtega"
            ><i class="fa fa-twitter"></i
          ></a>
        </li>
        <li>
          <a class="" href="https://instagram.com/drtonyortega/"
            ><i class="fa fa-instagram"></i
          ></a>
        </li>
        <li>
          <a href="#" class="mobile-nav"><i class="fa fa-bars"></i></a>
        </li>
      </ul>
    </header>
  </div>
</template>
<script>
export default {
  name: "topBar"
};
</script>
