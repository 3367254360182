import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import isHeHereYet from "../views/is-he-here-yet.vue";
import areYouHereYet from "../views/are-you-here-yet.vue";
import aboutTony from "../views/about-tony.vue";
import psychotherapyServices from "../views/psychotherapy-services.vue";
import lgbtqIssues from "../views/lgbtq-issues.vue";
import evaulationServices from "../views/evaulation-services.vue";
import insuranceAndFees from "../views/insurance-and-fees.vue";
import recommendedReadings from "../views/recommended-readings.vue";
import media from "../views/media.vue";
import contactMe from "../views/contact-me.vue";
import blog from "../views/blog.vue";
// import intakeForm from "../views/intake-form.vue";
import aCourseInMiracles from "../views/a-course-in-miracles.vue";
import lifeCoaching from "../views/life-coaching.vue";
import aboutStephen from "../views/about-stephen.vue";
import forms from "../views/forms.vue";
import post from "../views/post.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/is-he-here-yet",
    name: "is-he-here-yet",
    component: isHeHereYet
  },
  {
    path: "/are-you-here-yet",
    name: "are-you-here-yet",
    component: areYouHereYet
  },
  {
    path: "/psychotherapy-services",
    name: "psychotherapy-services",
    component: psychotherapyServices
  },
  {
    path: "/lgbtq-issues",
    name: "lgbtq-issues",
    component: lgbtqIssues
  },
  {
    path: "/evaulation-services",
    name: "evaulation-services",
    component: evaulationServices
  },
  {
    path: "/insurance-and-fees",
    name: "insurance-and-fees",
    component: insuranceAndFees
  },
  {
    path: "/recommended-readings",
    name: "recommended-readings",
    component: recommendedReadings
  },
  {
    path: "/media",
    name: "media",
    component: media
  },
  {
    path: "/contact-me",
    name: "contact-me",
    component: contactMe
  },
  {
    path: "/about-tony",
    name: "about-tony",
    component: aboutTony
  },
  {
    path: "/blog",
    name: "blog",
    component: blog
  },
  {
    path: "/post/:id",
    name: "post",
    component: post
  },
  {
    path: '/intake-form',
    beforeEnter() { 
      location.href = 'https://tally.so/r/3X07DY';
    }
  },
  {
    path: "/a-course-in-miracles",
    name: "a-course-in-miracles",
    component: aCourseInMiracles
  },
  {
    path: "/life-coaching",
    name: "life-coaching",
    component: lifeCoaching
  },
  {
    path: "/about-stephen",
    name: "about-stephen",
    component: aboutStephen
  },
  {
    path: "/forms",
    name: "forms",
    component: forms
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
