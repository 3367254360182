<template>
  <div>
    <footer>
      <p class="left">Verified by Psychology Today - Dr. Tony Ortega</p>
      <p class="right">
        <a href="https://blacktiecollab.com/"
          >Part of the Blacktie Collaborative Network</a
        >
      </p>
    </footer>
    <div class="newsletter" ref="newsletter">
      <a href="#" class="tab open" ref="tab">Get your free gift!</a>
      <p>
        Sign up for my newsletter and receive a free ebook,
        <i>Finding Your Voice!</i>
      </p>
      <form accept-charset="UTF-8" @submit.prevent="subscibeNewsletter()">
        <input type="text" placeholder="Full Name" v-model="name" required />
        <input
          type="email"
          placeholder="Email Address"
          v-model="email"
          required
          style="color:white"
        />
        <button class="more"><i class="fa fa-long-arrow-right"></i></button>
      </form>
    </div>
  </div>
</template>
<script>
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export default {
  data() {
    return {
      name: "",
      email: ""
    };
  },
  methods: {
    async subscibeNewsletter() {
      const body = {
        name: this.name,
        email: this.email
      };
      const response = await fetch("https://emails-blacktiecollab-com.onrender.com/drtony-subscribe-newsletter", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json"
        }
      }).catch(err => {
        console.log(err);
        alert("Error Try Again");
      });
      if (response) {
        const data = await response.json();
        if (data.errors) {
          alert("Invalid Input. Please Check");
        } else {
          alert("Success. Check Your Email!");
          this.name = "";
          this.email = "";
          this.$refs["tab"].classList.remove("closed");
          this.$refs["tab"].classList.add("open");
          for (let i = -1; i > -362; i--) {
            await sleep(1);
            this.$refs["newsletter"].style.right = `${i}px`;
          }
        }
      }
    }
  }
};
</script>
