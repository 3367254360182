<template>
  <div id="app">
    <topBar />
    <router-view />
    <mainFooter />
  </div>
</template>
<script>
import topBar from "@/components/top-bar.vue";
import mainFooter from "@/components/footer.vue";
export default {
  components: {
    topBar: topBar,
    mainFooter: mainFooter
  }
};
</script>
