<template>
  <div class="popular-posts">
    <h2 style="margin-top: 130px">Post Archive</h2>
    <div class="posts">
      <div
        class="post"
        v-for="post in posts.slice().reverse()"
        :key="post.id"
        :style="{
          background: 'url(' + post.image + ')',
          backgroundSize: 'cover'
        }"
      >
        <div class="post-content">
          <h1>
            {{ post.title }}
          </h1>
          <p>Written in: {{ getMonth(post.created_at) }}</p>
          <router-link :to="'/post/' + post.slug" class="button"
            >Read More</router-link
          >
          <a
            :href="post.twitter"
            onclick="window.open(this.href, 'mywin',
'left=20,top=20,width=500,height=500,toolbar=1,resizable=0'); return false;"
            class="button twitter"
            ><i class="fa fa-twitter"></i
          ></a>
          <a
            :href="post.facebook"
            class="button facebook"
            onclick="window.open(this.href, 'mywin',
'left=20,top=20,width=500,height=500,toolbar=1,resizable=0'); return false;"
            ><i class="fa fa-facebook"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import posts from "@/assets/posts.json";
export default {
  data() {
    return {
      posts
    };
  },
  methods: {
    getMonth(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleString("default", { month: "short" });
    }
  }
};
</script>
